import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { Meh } from 'lucide-react';
import { useState } from 'react';

const Boundary = () => {
  const error = useRouteError();
  // console.error(error);

  const [isDev, setIsDev] = useState<boolean>(false);

  let errorHeader = 'Oops, we hit a bump in the road!';
  let errorBody;
  let errorCta = <button onClick={() => window.location.reload()}>Refresh</button>;

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      errorHeader = "We can't find what you're looking for!";
      errorCta = <a href="/">Return Home</a>;
    }
    errorBody = isDev && <p className="font-light leading-loose">{error.data}</p>;
  }
  if (error instanceof Error) {
    errorBody = isDev && (
      <pre className="max-h-96 w-full max-w-5xl overflow-auto">{error.stack}</pre>
    );
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-3 border border-muted-foreground bg-muted p-6">
      <button onClick={() => setIsDev(!isDev)}>
        <Meh className="h-10 w-10" />
      </button>
      <h3 className="text-2xl font-semibold">{errorHeader}</h3>
      {errorBody}
      {errorCta}
    </div>
  );
};

export { Boundary };
